<template>
  <div class="bigbox">
    <div class="hot">
      <div class="logo">
        <img
          src="https://vehicle-library.oss-cn-beijing.aliyuncs.com/cvbd-icon/meeting/function_3.png"
          alt=""
        />
        <div>其他事项</div>
      </div>
      <div class="title">
        <div class="t-txt">{{ obj.tips }}</div>
        <div class="tflex">
          <div>
            <div class="num">{{ obj.notSignNum }}</div>
            <div class="">未入住</div>
          </div>
          <div>
            <div class="num">{{ obj.signedNum }}</div>
            <div>已入住</div>
          </div>
        </div>
      </div>
    </div>
    <div class="eat">
      <div class="logo">
        <img
          src="https://vehicle-library.oss-cn-beijing.aliyuncs.com/cvbd-icon/meeting/have_meals.png"
          alt=""
        />
        <div>用餐情况</div>
      </div>
      <div class="keys">
        <div class="title titles" v-for="(item, index) in list" :key="index">
          <div class="t-txt">{{ item.tips }}</div>
          <div class="tflex">
            <div>
              <div class="num" v-if="item.count">{{ item.count }}</div>
              <div class="num" v-else>{{ item.notSignNum }}</div>
              <div class="">预计人数</div>
            </div>
            <div>
              <div class="num">{{ item.signedNum }}</div>
              <div>实际人数</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getOverview, getOverviews } from "@/utils/http.js";
export default {
  data() {
    return {
      list: [],
      obj: "",
      timer: "",
    };
  },
  created() {},
  mounted() {
    let id = this.$route.query.id;
    this.getSimpleTechAppraisals(id, 1);
    this.setTimer(id);
  },
  methods: {
    async getSimpleTechAppraisals(activityId, key) {
      if (key == 1) {
        var { data: res } = await getOverview({ activityId });
      } else {
        var { data: res } = await getOverviews({ activityId });
      }
      let obj = "";
      let list = [];
      res.content.details.forEach((item) => {
        if (item.type == 2) {
          this.obj = item;
        }
        if (item.type > 4) {
          list.push(item);
        }
        if (item.type == 4) {
          obj = item;
        }
      });
      if(obj){
        list.push(obj);
      }
      this.list = list;
    },
    setTimer(activityId) {
      this.timer = setInterval(() => {
        this.getSimpleTechAppraisals(activityId, 2);
      }, 1000);
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>
<style scoped>
.bigbox {
  width: 100%;
  height: 100vh;
  background: #f8f8f8;
  padding-top: 10px;
}
.hot {
  width: 375px;
  height: 157px;
  background: #ffffff;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 15px;
}
.logo {
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 16px;
  color: #333333;
  display: flex;
  align-items: center;
}
.logo img {
  width: 21px;
  height: 21px;
  margin-right: 6px;
}
.title {
  width: 167px;
  /* height: 94px; */
  background: #ececec;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 16px 20px;
  margin-top: 12px;
}
.t-txt {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 15px;
  color: #333333;
  margin-bottom: 10px;
}
.tflex {
  display: flex;
  justify-content: space-between;
  /* margin-top: 20px; */
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
  text-align: center;
}
.num {
  font-size: 18px;
  margin-bottom: 2px;
  font-weight: 500;
}
.titles {
  margin-top: 10px;
}
.eat {
  width: 375px;
  /* height: 157px; */
  background: #ffffff;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 15px;
  margin-top: 10px;
}
.keys {
  display: flex;
  flex-wrap: wrap;
}
.keys > div:nth-child(odd) {
  margin-right: 10px;
}
</style>